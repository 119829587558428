import React from "react";
import "./Field.css";
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import logo from "./logo.svg";
import RefreshArrow from "./ArrowIcon";
import EditField from "./EditField/EditField";

export default function Field(props) {
  let pixagriToken = localStorage.getItem("token");
  let fieldDataToken = localStorage.getItem("fieldDataToken");
  let crop_id;
  const importStateArray = localStorage.getItem("importState");
  const url = "https://atlas.node.api.terranis.fr";
  // const url = "http://192.168.12.64:3000";

  const [importBtnState, setImportBtnState] = useState(true);

  const [editFieldState, setEditFieldState] = useState();
  const [nameState, setNameState] = useState();
  const [idState, setIdState] = useState();
  const [soilTypeState, setSoilTypeState] = useState([]);
  const [irrigationTypeState, setIrrigationTypeState] = useState([]);

  const [selectedSoilIdState, setSelectedSoilIdState] = useState();
  const [editedSoilIdState, setEditedSoilIdState] = useState();
  const [editedIrrigationIdState, setEditedIrrigationIdState] = useState();
  const [selectedIrrigationIdState, setSelectedIrrigationIdState] = useState();
  const [seasonStartDate, setSeasonStartDate] = useState();
  const [geometryState, setGeometryState] = useState();

  const api_url = "https://wago.dev.terranis.fr/api";

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("code");
    localStorage.removeItem("fieldDataToken");
    localStorage.removeItem("token");
    localStorage.removeItem("irrigation");
    localStorage.removeItem("soil");
    localStorage.removeItem("importState");
    window.location.replace("https://atlas.webapp.terranis.fr");
  };

  const askAdvice = (urn) => {
    let advice_url = `https://irrigationadvisor.terranis.fr/advices`;
    let today = new Date().toISOString().split("T")[0];
    fetch(advice_url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${pixagriToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        field_urn: urn,
        application: {
          date: `${today}`,
          ratio: 100,
        },
      }),
    })
      .then((res) => {
        if (res.status === 401) {
          logout();
        }
        return res.json();
      })
      .then((data) => {
        props.popup("Geopackage in process");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editField = () => {
    if (editFieldState === undefined || editFieldState === false) {
      setEditFieldState(true);
    } else if (editFieldState === true) {
      setEditFieldState(false);
      props.importFields();
    }
  };

  const closablePopup = (message) => {
    const popup = document.createElement("div");
    popup.setAttribute("id", "infoPopup");
    popup.classList.add("infoPopup");
    popup.textContent = message;
    const parent = document.getElementsByClassName("fieldBox")[0];
    parent.append(popup);
    const closeBtn = document.createElement("div");
    closeBtn.textContent = "x";
    closeBtn.classList.add("closeBtn");
    closeBtn.addEventListener("click", () => {
      parent.removeChild(popup);
    });
    popup.append(closeBtn);
  };

  const setCrop = () => {
    props.crop.forEach((element) => {
      if (element.atlas_id === idState) {
        crop_id = element.crop_id;
      }
    });
  };

  const sendSoil = (soil) => {
    const myId = parseInt(soil);
    const obj = props.soils;
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (myId === parseInt(element.soil_id)) {
          setSelectedSoilIdState(myId);
        }
      }
    }
  };

  const importCard = (urn, name) => {
    const infoPopup = document.getElementById("infoPopup");
    if (infoPopup) {
      const parent = document.getElementsByClassName("fieldBox")[0];
      parent.removeChild(infoPopup);
    }
    let post_fields_url = `${api_url}/atlas/fields`;
    let geometry = "";
    for (let i = 0; i < geometryState.length; i++) {
      const element = geometryState[i];
      geometry += `${element[0]} ${element[1]}`;

      if (geometryState[i + 1]) {
        geometry += `,`;
      }
    }
    const polygon = `POLYGON ((${geometry}))`;

    setCrop();

    if (
      name &&
      polygon &&
      selectedSoilIdState &&
      urn &&
      seasonStartDate &&
      selectedIrrigationIdState &&
      crop_id
    ) {
      fetch(post_fields_url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
        },
        body: JSON.stringify({
          field_name: name,
          comments: `atlas import ${name}`,
          geometry: polygon,
          group: "ATLAS",
          soil_id: selectedSoilIdState,
          field_urn: urn,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.field_id) {
            fetch(`${api_url}/fields/${data.field_id}/rotations`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${pixagriToken}`,
              },
              body: JSON.stringify({
                sowing_date: seasonStartDate,
                sys_irrigation_id: selectedIrrigationIdState,
                crop_id: crop_id,
              }),
            });
            setImportBtnState(false);
            setEditedSoilIdState(selectedSoilIdState);
            setEditedIrrigationIdState(selectedIrrigationIdState);

            return;
          }
        });
    } else {
      let params = {
        name: name,
        polygon: polygon,
        soil_id: selectedSoilIdState,
        urn: urn,
        season_start_date: seasonStartDate,
        irrigation_id: selectedIrrigationIdState,
        crop_id: crop_id,
      };
      let undefinedParam = [];

      if (
        (params.crop_id === undefined || params.crop_id === null) &&
        params.season_start_date !== undefined &&
        params.season_start_date !== null
      ) {
        closablePopup(
          "This crop is not handled by our service, please contact us at pixagri@terranis.fr"
        );
      } else if (
        (!params.crop_id && !params.season_start_date) ||
        !params.season_start_date
      ) {
        closablePopup(
          "Configuration is required, please indicate a crop and/or sowing date into field_data service"
        );
      } else {
        for (const key in params) {
          if (Object.hasOwnProperty.call(params, key)) {
            const element = params[key];
            if (element === undefined || element === null) {
              undefinedParam.push(key);
            }
          }
        }
        closablePopup("Missing parameter(s): " + undefinedParam);
      }
    }
  };

  const sendIrrigation = (irrigation) => {
    const myId = parseInt(irrigation);
    const obj = props.irrigation;
    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const element = obj[key];
        if (myId === parseInt(element.sys_irrigation_id)) {
          setSelectedIrrigationIdState(myId);
        }
      }
    }
  };

  const setEdit = () => {
    if (importStateArray) {
      let array = JSON.parse(importStateArray)[0];
      array.forEach((el) => {
        if (el.field_urn === props.element.urn) {
          setImportBtnState(false);
          const soil_id = el.soil_id;
          const sys_irrigation_id = el.rotations[0].sys_irrigation_id;
          if (soil_id && sys_irrigation_id) {
            setEditedSoilIdState(soil_id);
            setEditedIrrigationIdState(sys_irrigation_id);
          }
        }
      });
    }
  };

  const getData = () => {
    let today = new Date().toISOString().split("T")[0];
    fetch(`${url}/fields/${props.element.urn}/crops/${today}`, {
      headers: {
        Authorization: `Bearer ${pixagriToken}`,
        auth: `Basic ${fieldDataToken}`,
      },
    }).then((res) => {
      return res.json().then((data) => {
        setSeasonStartDate(data.season_start_date);
        setNameState(data.name);
        setIdState(data.id);
      });
    });
  };

  const refreshIrrigation = () => {
    fetch(
      `${url}/fields/${props.element.urn}/application_result/${seasonStartDate}`,
      {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
          auth: `Basic ${fieldDataToken}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401) {
          logout();
        }
        return res.json();
      })
      .then((data) => {
        const area = props.element.area;
        data.forEach((element) => {
          const dose_mm = element.application_info.amount / (10 * area);
          const irrigation_date = element.date.split("T")[0];
          const fieldArray = JSON.parse(localStorage.getItem("importState"))[0];
          fieldArray.forEach((element) => {
            if (
              element.field_urn === props.element.urn &&
              dose_mm &&
              irrigation_date
            ) {
              fetch(
                `${api_url}/fields/${element.field_id}/rotations/${element.rotations[0].rotation_id}/irrigate`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${pixagriToken}`,
                  },
                  body: JSON.stringify({
                    dose_mm: dose_mm,
                    irrigation_date: irrigation_date,
                  }),
                }
              ).then((res) => {
                // console.log(res);
                if (res.status === 409) {
                  fetch(
                    `${api_url}/fields/${element.field_id}/rotations/${element.rotations[0].rotation_id}/irrigate`,
                    {
                      method: "PUT",
                      headers: {
                        Authorization: `Bearer ${pixagriToken}`,
                      },
                      body: JSON.stringify({
                        dose_mm: dose_mm,
                        irrigation_date: irrigation_date,
                      }),
                    }
                  );
                }
              });
            }
          });
        });
      });
  };

  useEffect(() => {
    getData();
    setEdit();
    setSoilTypeState(props.soils);
    if (props.irrigation.length > 0) {
      setSelectedIrrigationIdState(props.irrigation[0].sys_irrigation_id || []);
      setIrrigationTypeState(props.irrigation);
    } else {
      setTimeout(() => {
        let irrigationArray = JSON.parse(localStorage.getItem("irrigation"));
        setSelectedIrrigationIdState(irrigationArray[0].sys_irrigation_id);
        setEditedIrrigationIdState(irrigationArray[0].sys_irrigation_id);
        setIrrigationTypeState(irrigationArray);
      }, 700);
    }

    if (props.soils.length > 0) {
      setSelectedSoilIdState(props.soils[0].soil_id || []);
    } else {
      setTimeout(() => {
        let soilArray = JSON.parse(localStorage.getItem("soil"));
        setSelectedSoilIdState(soilArray[0].soil_id);
      }, 700);
    }

    setGeometryState(props.element.coordinates);
  }, []);

  return (
    <div className="fade-in-image">
      {editFieldState === false || editFieldState === undefined ? (
        <div className="card">
          <div className="topCard">
            <button className="info">?</button>
            <p className="cardName">{props.element.name}</p>
            <p className="italic">{props.element.urn.split(":")[2]}</p>
            <img className="logoImage" src={logo} alt="test"></img>
          </div>
          <div className="botCard">
            <p className="botCardInput">{nameState}</p>
            {importBtnState ? (
              <select
                onChange={(e) => sendSoil(e.target.value)}
                className="botCardInput botCardSelect"
                name="soil"
                id="soil"
                value={selectedSoilIdState}
              >
                <option disabled className="soilDisabled" value="soil">
                  Soil
                </option>
                {soilTypeState.map((soil) => (
                  <option value={soil.soil_id} key={uuidv4()}>
                    {soil.soil_code.charAt(0).toUpperCase()}
                    {soil.soil_code.replaceAll("_", " ").slice(1)}
                  </option>
                ))}
              </select>
            ) : (
              <select
                onChange={(e) => sendSoil(e.target.value)}
                className="botCardInput botCardSelect"
                name="soil"
                id="soil"
                disabled={!importBtnState ? true : false}
                value={editedSoilIdState}
              >
                <option disabled className="soilDisabled" value="soil">
                  Soil
                </option>
                {soilTypeState.map((soil) => (
                  <option value={soil.soil_id} key={uuidv4()}>
                    {soil.soil_code.charAt(0).toUpperCase()}
                    {soil.soil_code.replaceAll("_", " ").slice(1)}
                  </option>
                ))}
              </select>
            )}
            {importBtnState ? (
              <select
                onChange={(e) => sendIrrigation(e.target.value)}
                className="botCardInput botCardSelect"
                name="irrigation"
                id="irrigation"
                disabled={!importBtnState ? true : false}
                value={
                  !importBtnState
                    ? props.element.sys_irrigation_id
                    : selectedIrrigationIdState
                }
              >
                <option disabled value="irrigation">
                  Irrigation
                </option>
                {irrigationTypeState.map((irrigation) => (
                  <option value={irrigation.sys_irrigation_id} key={uuidv4()}>
                    {irrigation.sys_irrigation_code.charAt(0).toUpperCase()}
                    {irrigation.sys_irrigation_code
                      .replaceAll("_", " ")
                      .slice(1)}
                  </option>
                ))}
              </select>
            ) : (
              <select
                onChange={(e) => sendIrrigation(e.target.value)}
                className="botCardInput botCardSelect"
                name="irrigation"
                id="irrigation"
                disabled={!importBtnState ? true : false}
                value={editedIrrigationIdState}
              >
                <option disabled value="irrigation">
                  Irrigation
                </option>
                {irrigationTypeState.map((irrigation) => (
                  <option value={irrigation.sys_irrigation_id} key={uuidv4()}>
                    {irrigation.sys_irrigation_code.charAt(0).toUpperCase()}
                    {irrigation.sys_irrigation_code
                      .replaceAll("_", " ")
                      .slice(1)}
                  </option>
                ))}
              </select>
            )}
            <div className="cardBtnBox">
              {!importBtnState ? (
                <button className="cardBtn" onClick={editField}>
                  EDIT
                </button>
              ) : (
                ""
              )}
              {!importBtnState ? (
                <button
                  className="cardBtn"
                  onClick={() => askAdvice(props.element.urn)}
                >
                  ASK ADVICE
                </button>
              ) : (
                ""
              )}
              {importBtnState ? (
                <button
                  onClick={() =>
                    importCard(props.element.urn, props.element.name)
                  }
                  className="cardBtn"
                >
                  IMPORT
                </button>
              ) : (
                <>
                  <button className="cardBtn alreadyImported" disabled>
                    ALREADY IMPORTED
                  </button>
                  <button
                    className="cardBtn"
                    onClick={() => refreshIrrigation()}
                  >
                    <RefreshArrow>
                      <path d={props.d} />
                    </RefreshArrow>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <EditField
          urn={props.element.urn}
          name={props.element.urn.name}
          soils={props.soils}
          irrigation={JSON.parse(localStorage.getItem("irrigation"))}
          importState={JSON.parse(localStorage.getItem("importState"))[0]}
          validate={editField}
          popup={props.popup}
        />
      )}
    </div>
  );
}
