import React, { useState } from "react";
import { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import { v4 as uuidv4 } from "uuid";
import Advice from "../Advice/Advice";
import "./Advices.css";
import CircularProgress from "@mui/material/CircularProgress";

export default function Advices() {
  const [dataState, setDataState] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const pixagriToken = localStorage.getItem("token");

  const [formadviceState, setFormadviceState] = useState(false);

  const deleteadvice = (id) => {
    const newDataState = [...dataState].filter((data) => data.id !== id);
    setDataState(newDataState);
  };

  const openFormadvice = () => {
    setFormadviceState(true);
  };

  const prepareadvice = () => {
    setFormadviceState(!formadviceState);
  };

  useEffect(() => {
    fetch(`https://wago.dev.terranis.fr/api/atlas/fields`, {
      headers: {
        Authorization: `Bearer ${pixagriToken}`,
      },
    })
      .then((res) => {
        if (res.status === 401) {
          return window.location.replace("https://atlas.webapp.terranis.fr");
        }
        return res.json();
      })
      .then((data) => {
        if (data.length > 0) {
          setDataState(data);
          setIsFetching(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsFetching(false);
        setDataState("An error ocurred");
      });
  }, []);

  return (
    <>
      <Navbar txt=" - ADVICES" />
      {isFetching ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <div className="advicesDiv">
        <div className="flexAdviceItems">
          {dataState.map((field) => (
            <Advice
              field_name={field.field_name}
              field_urn={field.field_urn}
              advices={field.advices}
              deleteFunc={deleteadvice}
              key={uuidv4()}
              openFormFunc={openFormadvice}
            />
          ))}
        </div>
      </div>
    </>
  );
}
