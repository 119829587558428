import React, { useState } from "react";
import "./Navbar.css";
import { NavLink, useNavigate } from "react-router-dom";
import User from "../User/User";
import PairIcon from "../PairIcon/PairIcon";

export default function Navbar(props) {
  const [userSettingsState, setUserSettingsState] = useState(false);
  const navigate = useNavigate();
  const navToPairing = () => {
    navigate("/pairing");
  };
  const openUserSettings = () => {
    setUserSettingsState(!userSettingsState);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("code");
    localStorage.removeItem("fieldDataToken");
    localStorage.removeItem("token");
    localStorage.removeItem("irrigation");
    localStorage.removeItem("soil");
    localStorage.removeItem("importState");
    // const btn = document.getElementById('importFieldsBtn')
    //         btn.setAttribute('disabled', false)
    window.location.replace("https://atlas.webapp.terranis.fr");
  };

  return (
    <nav>
      <div className="firstHeader">
        <h3 id="inline-title">Irrigation Service {props.txt}</h3>
        <div className="navIcons">
          <PairIcon onClick={navToPairing}>
            <path d={props.d} />
          </PairIcon>
          <User onClick={openUserSettings}></User>
          {userSettingsState ? (
            <div className="userSettings">
              {/* <p
                className="userSettingsItems"
                onClick={() => {
                  userParams();
                }}
              >
                Parameters
              </p> */}
              <p
                className="userSettingsItems"
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="secondHeader">
        <NavLink
          className="secondHeaderItems"
          style={({ isActive }) => {
            return {
              color: isActive ? "white" : "",
              fontWeight: isActive ? "bold" : "",
            };
          }}
          to="/fields"
        >
          FIELDS
        </NavLink>
        <NavLink
          className="secondHeaderItems"
          style={({ isActive }) => {
            return {
              color: isActive ? "white" : "",
              fontWeight: isActive ? "bold" : "",
            };
          }}
          to="/advices"
        >
          ADVICES
        </NavLink>
      </div>
    </nav>
  );
}
