import React from "react";
import { useState } from "react";
import logo from "./atlas-logo.png";
import "./LoginForm.css";
import { useNavigate } from "react-router-dom";

export default function LoginForm(props) {
  const [loginState, setLoginState] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const navigate = useNavigate();

  const validateForm = () => {
    if (loginState && passwordState) {
      return loginState.length > 0 && passwordState.length > 0;
    }
  };

  const handleSubmit = function (e) {
    e.preventDefault();

    const login = "https://wago.dev.terranis.fr/api/login";

    const form = {
      email: document.querySelector("#signin-email"),
      password: document.querySelector("#signin-password"),
      submit: document.querySelector("#signin-btn-submit"),
    };

    fetch(login, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: form.email.value,
        password: form.password.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Login failed.") {
          alert("Error Password and/or Username");
        } else {
          props.stateChanger(true);
          localStorage.setItem('token', data.jwt)
          localStorage.setItem("user", JSON.stringify(data));
          navigate("/fields");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="loginBox">
      <img src={logo} alt="logo_atlas" />
      <h1 className="loginTitle">Irrigation Advisor Service</h1>
      <form className="flexForm" onSubmit={handleSubmit}>
        <label htmlFor="login">login</label>
        <input
          id="signin-email"
          autoFocus
          onChange={(e) => setLoginState(e.target.value)}
          value={loginState}
          type="text"
          name="login"
          required
        />
        <label htmlFor="password">password</label>
        <input
          id="signin-password"
          onChange={(e) => setPasswordState(e.target.value)}
          value={passwordState}
          type="password"
          name="password"
          required
        />
        <button
          className={
            validateForm() ? "loginSubmit" : "loginSubmit disabledSubmit"
          }
          disabled={!validateForm()}
        >
          Login
        </button>
      </form>
    </div>
  );
}
