import React from "react";
import Navbar from "./Navbar/Navbar";

export default function Dashboard() {
  return (
    <>
      <Navbar />
      <p>Dashboard</p>
    </>
  );
}
