import Navbar from "../Navbar/Navbar";
import "./Pairing.css";
// import axios from "axios";

export default function Pairing(data) {
  const code = window.location.search.split("code=")[1];

  localStorage.setItem("code", code);

  const showPopup = (message) => {
    const popup = document.createElement("div");
    popup.classList.add("infoPopup");
    setTimeout(() => {
      popup.classList.add("fadeOut");
    }, 1000);
    popup.textContent = message;
    const parent = document.getElementsByClassName("pairingFlexBox")[0];
    parent.append(popup);
    setTimeout(() => {
      parent.removeChild(popup);
    }, 2000);
  };

  const addField = () => {
    window.location.replace(
      "https://sso.staging.agricircle.com/auth/realms/atlas/protocol/openid-connect/auth?client_id=customer_atlas_terranis&redirect_uri=http://localhost:8080/pairing&response_type=code&scope=openid"
    );
    if (localStorage.getItem("code") !== undefined) {
      // showPopup("Successfully paired");
    }
  };

  return (
    <div className="pairingFlexBox">
      <Navbar txt=" - PAIRING"></Navbar>
      <div className="fieldDataForm">
        <p>FIELD DATA</p>
        <button
          onClick={() => {
            addField();
          }}
          className="addFieldBtn"
        >
          PAIR
        </button>
      </div>
    </div>
  );
}
