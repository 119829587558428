import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "./atlas-logo.png";
import "./NotFound.css"


export default function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="notFound">
      <img src={logo} alt="logo_atlas" />
      <p>Page Not Found</p>
      <button className="notFoundBtn" onClick={() => navigate("/")}>Please login</button>
    </div>
  );
}
