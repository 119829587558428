import "./App.css";
import LoginForm from "./Components/LoginForm/LoginForm";
import Dashboard from "./Components/Dashboard/Dashboard";
import NotFound from "./Components/NotFound/NotFound";
import { Routes, Route } from "react-router-dom";
import Fields from "./Components/Dashboard/Fields/Fields";
import Pairing from "./Components/Dashboard/Pairing/Pairing";
import Advices from "./Components/Dashboard/Advices/Advices";
import { useState, useEffect } from "react";
import UserParams from "./Components/Dashboard/User/UserParams";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setUser(foundUser)
      setIsLoggedIn(true);
    }
  }, []);

  if (!isLoggedIn) {
    return (
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={<LoginForm stateChanger={setIsLoggedIn} />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  } else if (isLoggedIn) {
    return (
      <div className="App">
        <Routes>
          <Route path="/" element={<LoginForm stateChanger={setIsLoggedIn} />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/fields" element={<Fields />} />
          <Route path="/advices" element={<Advices />} />
          <Route path="/pairing" element={<Pairing />} />
          <Route path="/userParams" element={<UserParams />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    );
  }
}

export default App;
