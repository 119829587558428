import React from "react";
import Navbar from "../Navbar/Navbar";
import Field from "../Field/Field.js";
import "./Fields.css";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Fields() {
  const token = localStorage.getItem("token");
  const url = "https://atlas.node.api.terranis.fr";
  // const url = "http://192.168.12.64:3000";
  const btn = document.getElementById("importFieldsBtn");
  const [isFetching, setIsFetching] = useState(true);

  const showPopup = (message) => {
    const popup = document.createElement("div");
    popup.classList.add("infoPopup");
    popup.classList.add("fadePopup");
    setTimeout(() => {
      popup.classList.add("fadeOut");
    }, 1000);
    popup.textContent = message;
    const parent = document.getElementsByClassName("fieldBox")[0];
    parent.append(popup);
    setTimeout(() => {
      parent.removeChild(popup);
    }, 2000);
  };

  const showArrow = () => {
    const parent = document.getElementsByClassName("icon")[0];
    parent.classList.add('hidePlug')
    setTimeout(() => {
      parent.classList.remove('hidePlug')
    }, 300);
    setTimeout(() => {
      parent.classList.add('hidePlug')
    }, 600);
    setTimeout(() => {
      parent.classList.remove('hidePlug')
    }, 900);
    setTimeout(() => {
      parent.classList.add('hidePlug')
    }, 1200);
    setTimeout(() => {
      parent.classList.remove('hidePlug')
    }, 1500);
  }

  useEffect(() => {
    fetch(`${url}/code?code=${localStorage.getItem("code")}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.status === 401) {
          window.location.replace("https://atlas.webapp.terranis.fr");
        }
        return res.json();
      })
      .then((data) => {
        if (data.access_token) {
          localStorage.setItem("fieldDataToken", data.access_token);
        }
      })
      .catch((error) => {
        setPairMessage("Failed to fetch data.");
        setIsFetching(false);
      });

    setTimeout(() => {
      if (localStorage.getItem("fieldDataToken")) {
        setIsFetching(true);
        importFields();
        setPairMessage("");
      } else {
        setIsFetching(false);
        setPairMessage("Please pair your fields with the plug button.");
        showArrow()

      }
    }, 300);
  }, [token]);

  const [dataFieldState, setDataFieldState] = useState([]);
  const [dataFieldState2, setDataFieldState2] = useState([]);
  const [dataFieldState3, setDataFieldState3] = useState([]);
  const [cropCorrespondenceState, setCropCorrespondenceState] = useState([]);
  const [importStatusState, setImportStatusState] = useState([]);
  const [pairMessage, setPairMessage] = useState();

  const importFields = () => {
    let pixagriToken = localStorage.getItem("token");
    let fieldDataToken = localStorage.getItem("fieldDataToken");
    if (pixagriToken && fieldDataToken) {
      setIsFetching(true);
      setPairMessage("");
      fetch(`${url}/fields`, {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
          auth: `Basic ${fieldDataToken}`,
        },
      })
        .then((res) => {
          return res.json().then((data) => {
            setPairMessage("");
            setIsFetching(false);
            const array = [];
            data.forEach((element) => {
              const obj = {
                name: element.name,
                urn: element.urn,
                coordinates: element.boundaries.coordinates[0],
                area: element.area
              };
              array.push(obj);
            });
            setDataFieldState(array);
          });
        })
        .catch((err) => {
          console.log(err);
        });

      fetch(`https://wago.dev.terranis.fr/api/soils`, {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let soilTable = [];
          data.forEach((element) => {
            soilTable.push({
              soil_id: element.soil_id,
              soil_type: element.soil_type,
              soil_code: element.soil_code,
            });
          });
          localStorage.setItem("soil", JSON.stringify(soilTable));
          return setDataFieldState2(soilTable);
        });

      fetch(`https://wago.dev.terranis.fr/api/sys_irrigation`, {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
        },
      })
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            return res.json();
          } else {
            localStorage.removeItem("user");
          }
        })
        .then((data) => {
          let irrigationTable = [];

          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            irrigationTable.push({
              sys_irrigation_id: element.sys_irrigation_id,
              irrigation_type: element.irrigation_type,
              sys_irrigation_code: element.sys_irrigation_code,
            });
          }
          localStorage.setItem("irrigation", JSON.stringify(irrigationTable));
          return setDataFieldState3(irrigationTable);
        });

      fetch(`https://wago.dev.terranis.fr/api/atlas/fields`, {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.length > 0) {
            let array = [];
            array.push(data);
            localStorage.setItem("importState", JSON.stringify(array));
            return setImportStatusState(array);
          } else {
            localStorage.setItem("importState", []);
          }
        });

      fetch(`https://wago.dev.terranis.fr/api/atlas/crop_correspondence`, {
        headers: {
          Authorization: `Bearer ${pixagriToken}`,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          let atlasIdTable = [];
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            atlasIdTable.push({
              atlas_id: element.atlas_id,
              crop_id: element.crop_id,
            });
          }
          return setCropCorrespondenceState(atlasIdTable);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteCard = (param) => {
    // console.log(dataFieldState);
    // const newDataFieldState = Object.keys(dataFieldState)
    //   .filter((key) => param.includes(key))
    //   .reduce((obj, key) => {
    //     obj[key] = dataFieldState[key];
    //     return obj;
    //   }, {});
    // setDataFieldState(newDataFieldState);
    console.log(param);
  };

  return (
    <>
      <Navbar txt=" - FIELDS" />
      {/* <button
        id="importFieldsBtn"
        onClick={importFields}
        className="importFields"
      >
        IMPORT FIELDS
      </button> */}
      {isFetching ? (
        <div className="loader">
          <CircularProgress />
        </div>
      ) : (
        ""
      )}
      <div className="fieldBox">
        {pairMessage}
        {Object.keys(dataFieldState).length > 0
          ? dataFieldState.map((el) => (
              <Field
                element={{
                  name: el.name,
                  urn: el.urn,
                  soil_id: el.soil_id,
                  sys_irrigation_id: el.sys_irrigation_id,
                  coordinates: el.coordinates,
                  area: el.area
                }}
                soils={dataFieldState2}
                irrigation={dataFieldState3}
                crop={cropCorrespondenceState}
                deleteFunc={deleteCard}
                importState={importStatusState}
                importFields={importFields}
                popup={showPopup}
                key={uuidv4()}
              />
            ))
          : ""}
      </div>
    </>
  );
}
