import React, { Component } from "react";
import Navbar from "../Navbar/Navbar";

export default class UserParams extends Component {
  render(props) {
    return (
      <>
        <Navbar />
        <div>{props}</div>
      </>
    );
  }
}
