import React from "react";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

export default function EditField(props) {
  const url = "https://wago.dev.terranis.fr/api";
  const [selectedSoil, setSelectedSoil] = useState();
  const [selectedIrrigation, setSelectedIrrigation] = useState();

  const selectSoil = (soil) => {
    setSelectedSoil(soil);
  };

  const selectIrrigation = (irrigation) => {
    setSelectedIrrigation(irrigation);
  };

  const editField = (edited_urn, edited_irrigation_id, edited_soil_id) => {
    props.importState.forEach((element) => {
      if (edited_urn === element.field_urn) {
        fetch(`${url}/fields/${element.field_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          method: "PUT",
          body: JSON.stringify({
            soil_id: selectedSoil,
          }),
        })
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
            }
          })
          .catch((error) => {
            console.log(error);
          });

        fetch(
          `${url}/fields/${element.field_id}/rotations/${element.rotations[0].rotation_id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            method: "PUT",
            body: JSON.stringify({
              sys_irrigation_id: selectedIrrigation,
            }),
          }
        )
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              props.validate();
              props.popup("Successfully modified !");
            } else {
              localStorage.removeItem("user");
              window.location.replace("https://atlas.webapp.terranis.fr");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      // props.validate();
    });
  };

  useEffect(() => {
    setSelectedSoil(props.soils[0].soil_id);
    setSelectedIrrigation(props.irrigation[0].sys_irrigation_id);
  }, []);

  return (
    <div className="editCard">
      <h3 className="editTitle">Edit {props.name}</h3>
      <select
        onChange={(e) => selectSoil(e.target.value)}
        className="botCardInput botCardSelect"
        name="soil"
        id="soil"
        value={selectedSoil}
      >
        <option disabled className="soilDisabled" value="soil">
          -- Select Soil --
        </option>
        {props.soils.map((soil) => (
          <option value={soil.soil_id} key={uuidv4()}>
            {soil.soil_code.charAt(0).toUpperCase()}
            {soil.soil_code.replaceAll("_", " ").slice(1)}
          </option>
        ))}
      </select>
      <select
        onChange={(e) => selectIrrigation(e.target.value)}
        className="botCardInput botCardSelect"
        name="irrigation"
        id="irrigation"
        value={selectedIrrigation}
      >
        <option disabled className="soilDisabled" value="irrigation">
          -- Select Irrigation system --
        </option>
        {props.irrigation.map((irrigation) => (
          <option value={irrigation.sys_irrigation_id} key={uuidv4()}>
            {irrigation.sys_irrigation_code.charAt(0).toUpperCase()}
            {irrigation.sys_irrigation_code.replaceAll("_", " ").slice(1)}
          </option>
        ))}
      </select>
      <button
        className="cardBtn"
        onClick={() => editField(props.urn, selectedIrrigation, selectedSoil)}
      >
        VALIDATE
      </button>
    </div>
  );
}
